<template>
	<b-row>
		<b-col md="3" v-for="(goal, index) of goalTypeObject" :key="index">
			<b-card class="text-center height-90-per">
				<div nav-class="mb-3">
					<b-avatar class="mb-2" :variant="goal.variant" size="90">
						<feather-icon size="45" :icon="goal.avatar" />
					</b-avatar>
					<b-card-title>{{ goal.title }}</b-card-title>
					<b-card-text>{{ goal.description }}</b-card-text>
					<b-button ripple.400="'rgba(255, 255, 255, 0.15)'" @click="$emit('selected-goal-type', {
						goal: index,
						subgoal: goal.subgoal,
						goalDetail: goal
					  })" :variant="goal.buttonVariant">
						Select this goal
					</b-button>
				</div>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
	import { BRow, BCol, BCard, BCardBody, BCardTitle, BCardText, BAvatar, BButton } from 'bootstrap-vue'

	export default {
		components: {
			BRow, BCol, BCard, BCardBody, BCardTitle, BCardText, BAvatar, BButton
		},
		data() {
			return {
				/** GET ALL DETAILS OF EACH STYLE SECTIONS : START */
				goalTypeObject: {
					1: {
						title: 'Promote a Sale/Discount',
						description: "Point visitors to your latest deal, bargain, or giveaway.",
						avatar: 'DollarSignIcon',
						variant: 'light-warning',
						buttonVariant: 'outline-warning',
						subgoal: 4
					},
					2: {
						title: 'Talk to Your Visitors',
						description: "Encourage mobile visitors to give you a ring.",
						avatar: 'PhoneCallIcon',
						variant: 'light-primary',
						buttonVariant: 'outline-primary',
						subgoal: 2
					},
					3: {
						title: 'Grow Your Mailing List',
						description: "Convert one-time visits into lasting connections.",
						avatar: 'MailIcon',
						variant: 'light-success',
						buttonVariant: 'outline-success',
						subgoal: 1
					},
					4: {
						title: 'Get Facebook Likes',
						description: "Expand your social reach to maximize word of mouth.",
						avatar: 'ThumbsUpIcon',
						variant: 'light-info',
						buttonVariant: 'outline-info',
						subgoal: 3
					},
					5: {
						title: 'Announcement',
						description: "Display a simple announcement to your visitors",
						avatar: 'Volume2Icon',
						variant: 'light-success',
						buttonVariant: 'outline-success',
						subgoal: 5
					},
					6: {
						title: 'Subscribe to Text App',
						description: "Connect to Text App subscriber list directly.",
						avatar: 'SmartphoneIcon',
						variant: 'light-secondary',
						buttonVariant: 'outline-secondary',
						subgoal: 6
					},
					7: {
						title: 'Fortune Wheel',
						description: "You have a chance to win a nice big fat discount. Are you ready?.",
						avatar: 'GiftIcon',
						variant: 'light-danger',
						buttonVariant: 'outline-danger',
						subgoal: 7
					},
				}
				/** GET ALL DETAILS OF EACH STYLE SECTIONS : END */
			}
		},
	}
</script>