var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:" font-weight-bolder"},[_vm._v(_vm._s(_vm.prop_selectedGoalType.selectGoalText)+" > "+_vm._s(_vm.prop_selectedGoalType.selectStyleText))]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$emit('close-capture-details')}}},[_vm._v("Go Back")])],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('b-card',{staticClass:"card col-md-7"},[_c('validation-observer',{ref:"addNewCaptureForm"},[_c('b-form',{ref:"add-new-capture-form",attrs:{"id":"add-new-capture-form"}},[_c('b-card-title',[_vm._v("Announcement")]),(_vm.prop_selectedGoalType.selectStyleText != 'Slide Bar')?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"captureBarSay"}},[_vm._v("What should your headline say ?")]),_c('validation-provider',{attrs:{"name":"captureBarSay","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"captureBarSay","id":"captureBarSay","placeholder":"What you want to announce ?","state":errors.length > 0 ? false : null},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})]}}],null,false,2688877752)})],1)],1)],1):_vm._e(),(_vm.prop_selectedGoalType.selectStyleText == 'Slide Bar')?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"captureBarSay"}},[_vm._v("What you want to announce ?")]),_c('validation-provider',{attrs:{"name":"captureBarSay","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"captureBarSay","id":"captureBarSay","placeholder":"What you want to announce ?","state":errors.length > 0 ? false : null},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})]}}],null,false,2688877752)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"slideBarSay1"}},[_vm._v("What should your headline1 say ?")]),_c('validation-provider',{attrs:{"name":"slideBarSay1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"slideBarSay1","id":"slideBarSay1","placeholder":"What you want to announce ?","state":errors.length > 0 ? false : null},model:{value:(_vm.slideBarSay1),callback:function ($$v) {_vm.slideBarSay1=$$v},expression:"slideBarSay1"}})]}}],null,false,3026368178)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"slideBarSay2"}},[_vm._v("What should your headline2 say ?")]),_c('validation-provider',{attrs:{"name":"slideBarSay2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"slideBarSay2","id":"slideBarSay2","placeholder":"What you want to announce ?","state":errors.length > 0 ? false : null},model:{value:(_vm.slideBarSay2),callback:function ($$v) {_vm.slideBarSay2=$$v},expression:"slideBarSay2"}})]}}],null,false,534615985)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"slideBarSay3"}},[_vm._v("What should your headline3 say ?")]),_c('validation-provider',{attrs:{"name":"slideBarSay3","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"slideBarSay3","id":"slideBarSay3","placeholder":"What you want to announce ?","state":errors.length > 0 ? false : null},model:{value:(_vm.slideBarSay3),callback:function ($$v) {_vm.slideBarSay3=$$v},expression:"slideBarSay3"}})]}}],null,false,501476016)})],1)],1)],1):_vm._e(),_c('b-card-footer',{attrs:{"align":"right"}},[_c('b-button',{staticClass:"ml-2 float-right",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v("Continue")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.$emit('close-capture-details');}}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }