<template>
	<div>
		<div class="card">
            <div class="card-header">
                <h4 class=" font-weight-bolder">{{ prop_selectedGoalType.selectGoalText }} > {{ prop_selectedGoalType.selectStyleText }}</h4>
                <b-button variant="danger" class="" @click="$emit('close-capture-details')">Go Back</b-button>
            </div>
        </div>
		<div class="row justify-content-center">
			<b-card class="card col-md-7">
				<validation-observer ref="addNewCaptureForm">
					<b-form id="add-new-capture-form" ref="add-new-capture-form">
						<b-card-title>Announcement</b-card-title>
						<b-row v-if="prop_selectedGoalType.selectStyleText != 'Slide Bar'">
							<b-col md="12">
								<b-form-group>
									<label for="captureBarSay">What should your headline say ?</label>
									<validation-provider #default="{ errors }" name="captureBarSay" rules="required">
										<b-form-input name="captureBarSay" id="captureBarSay"
											placeholder="What you want to announce ?" v-model="input"
											:state="errors.length > 0 ? false : null" />
									</validation-provider>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row v-if="prop_selectedGoalType.selectStyleText == 'Slide Bar'">

							<b-col md="12">
								<b-form-group>
									<label for="captureBarSay">What you want to announce ?</label>
									<validation-provider #default="{ errors }" name="captureBarSay" rules="required">
										<b-form-input name="captureBarSay" id="captureBarSay"
											placeholder="What you want to announce ?" v-model="input"
											:state="errors.length > 0 ? false : null" />
									</validation-provider>
								</b-form-group>
							</b-col>


							<b-col md="12">
								<b-form-group>
									<label for="slideBarSay1">What should your headline1 say ?</label>
									<validation-provider #default="{ errors }" name="slideBarSay1" rules="required">
										<b-form-input name="slideBarSay1" id="slideBarSay1"
											placeholder="What you want to announce ?" v-model="slideBarSay1"
											:state="errors.length > 0 ? false : null" />
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col md="12">
								<b-form-group>
									<label for="slideBarSay2">What should your headline2 say ?</label>
									<validation-provider #default="{ errors }" name="slideBarSay2" rules="required">
										<b-form-input name="slideBarSay2" id="slideBarSay2"
											placeholder="What you want to announce ?" v-model="slideBarSay2"
											:state="errors.length > 0 ? false : null" />
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col md="12">
								<b-form-group>
									<label for="slideBarSay3">What should your headline3 say ?</label>
									<validation-provider #default="{ errors }" name="slideBarSay3" rules="required">
										<b-form-input name="slideBarSay3" id="slideBarSay3"
											placeholder="What you want to announce ?" v-model="slideBarSay3"
											:state="errors.length > 0 ? false : null" />
									</validation-provider>
								</b-form-group>
							</b-col>
						</b-row>
						<b-card-footer align="right">
							<b-button variant="primary" class="ml-2 float-right"
								@click.prevent="validationForm">Continue</b-button>
							<b-button variant="outline-danger" class="float-right"
								@click="$emit('close-capture-details');">Cancel</b-button>
						</b-card-footer>
					</b-form>
				</validation-observer>
			</b-card>
		</div>
	</div>
</template>

<script>
import {
	BRow, BCol, BButton, BCard, BInputGroup, BFormInput, BFormGroup, BCardTitle, BCardFooter, BForm
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
	components: {
		BRow, BCol, BInputGroup, BFormGroup, BCard, BFormInput, BButton, BCardTitle, BCardFooter, BForm,
		ValidationProvider, ValidationObserver
	},
	data() {
		return {
			input: 'What you want to announce ?',
			slideBarSay1: 'Announcement here!',
			slideBarSay2: 'Show Details information',
			slideBarSay3: 'Learn More',
			required
		}
	},
	props: {
		prop_selectedGoalType: {
			type: Object,
		}
	},
	methods: {
		goToMangeSiteCapture() {
			this.$router.push('/manage-site');
		},
		validationForm() {
			this.$refs.addNewCaptureForm.validate().then(success => {
				if (success) {
					let formData = new FormData(document.getElementById('add-new-capture-form'));
					const data = {};
					for (let [key, val] of formData.entries()) { /* NEED TO CONVERT IT BEFORE USING NOT WITH XMLHTTPREQUEST */
						Object.assign(data, { [key]: val })
					}
					this.$emit("formDataForAddNewCapture", data);
				}
			})
		},
	},
}
</script>