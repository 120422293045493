<template>
    <div class="">
        <div class="card">
            <div class="card-header">
                <h4 class=" font-weight-bolder">{{ prop_selectedGoalType.selectGoalText }} > Select Style</h4>
                <b-button variant="danger" class="" @click="$emit('close-style-type')">Go Back</b-button>
            </div>
        </div>
        <b-row>
            <b-col md="3" v-for="(style, index) of goalWiseStyle" :key="index" class="">
                <b-card class="text-center height-90-per">
                    <div nav-class="mb-3">
                        <b-avatar class="mb-1" variant="light-primary" size="90">
                            <font-awesome-icon :icon="style.avatar" class="fa-4x" />
                        </b-avatar>
                        <b-card-body>
                            <b-card-title>{{ style.title }}</b-card-title>
                            <b-card-text>{{ style.description }}</b-card-text>
                            <!-- <b-button ripple.400="'rgba(255, 255, 255, 0.15)'" @click="$emit('selected-style-type', index)" variant="outline-primary"> -->
                            <b-button ripple.400="'rgba(255, 255, 255, 0.15)'" @click="$emit('selected-style-type', {
                                style: index,
                                styleDetail: style
                              })" variant="outline-primary">
                                Select this style
                            </b-button>
                        </b-card-body>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import { BRow, BCol, BCardGroup, BCard, BCardBody, BAvatar, BButton, BCardText, BCardTitle } from 'bootstrap-vue'
    import captureIcons from "@/libs/capture-Icons"
    export default {
        components: {
            BRow, BCol, BCardGroup, BCard, BCardBody, BAvatar, BButton, BCardText, BCardTitle
        },
        data() {
            return {
                goalWiseStyle: _.pick(this.styleTypeIcons, ['1', '2', '3', '4', '6']),
            }
        },
        methods: {
            goalWiseStyleMethod(goalId) {
                if (goalId == 7) {
                    this.goalWiseStyle = _.pick(this.styleTypeIcons, '5');
                } else if (goalId == 5) {
                    this.goalWiseStyle = _.pick(this.styleTypeIcons, ['1', '2', '3', '4', '6', '7']);
                } else {
                    this.goalWiseStyle = _.pick(this.styleTypeIcons, ['1', '2', '3', '4', '6']);
                }
            }
        },
        props: {
            prop_selectedGoalType: {
                type: Object,
            }
        },
        setup() {
            const { styleTypeIcons } = captureIcons();
            return {
                styleTypeIcons,
            }
        },
        created(){
            this.goalWiseStyleMethod(this.prop_selectedGoalType.selectGoalType); /* CHANGE STYLE OBJECT SUBGOAL WISE */
        },
    }

</script>