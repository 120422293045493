var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:" font-weight-bolder"},[_vm._v(_vm._s(_vm.prop_selectedGoalType.selectGoalText)+" > "+_vm._s(_vm.prop_selectedGoalType.selectStyleText))]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$emit('close-capture-details')}}},[_vm._v("Go Back")])],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('b-card',{staticClass:"card col-md-7"},[_c('validation-observer',{ref:"addNewCaptureForm"},[_c('b-form',{ref:"add-new-capture-form",attrs:{"id":"add-new-capture-form"}},[_c('b-card-title',[_vm._v("Talk to Your Visitors")]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"captureBarSay"}},[_vm._v("What should your Capture say?")]),_c('validation-provider',{attrs:{"name":"captureBarSay","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"captureBarSay","id":"captureBarSay","placeholder":"Easily customize your order by talking to a real human!","state":errors.length > 0 ? false : null},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"buttonSay"}},[_vm._v("What should the button say?")]),_c('validation-provider',{attrs:{"name":"buttonSay","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"buttonSay","id":"buttonSay","placeholder":"Call us now!","state":errors.length > 0 ? false : null},model:{value:(_vm.button),callback:function ($$v) {_vm.button=$$v},expression:"button"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"phoneNumber"}},[_vm._v("What phone number should be dialed?")]),_c('validation-provider',{attrs:{"name":"phoneNumber","rules":"required|integer|min:10|between:0000000000,9999999999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"phoneNumber","id":"phoneNumber","placeholder":"(555) 555-555","state":errors.length > 0 ? false : null},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"country"}},[_vm._v("Which Country should have?")]),_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select2',{attrs:{"options":_vm.Options,"name":"country","id":"country","state":errors.length > 0 ? false : null},model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}})]}}])})],1)],1)],1),_c('b-card-footer',{attrs:{"align":"right"}},[_c('b-button',{staticClass:"ml-2 float-right",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Continue ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.$emit('close-capture-details');}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }