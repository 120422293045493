<template>
	<div>
		<!-- SELECT GOAL COMPONENT :: START -->
		<select-goal v-if="selectGoalType == ''" @selected-goal-type="selectedGoalType"></select-goal>
		<!-- SELECT GOAL COMPONENT :: END  -->

		<!-- SELECT STYLE COMPONENT :: START -->
		<select-style v-if="selectStyleType == '' && selectGoalType" :prop_selectedGoalType="{selectGoalType, selectGoalText}" @selected-style-type="selectedStyleType" @close-style-type="closeStyleSection"></select-style>
		<!-- SELECT STYLE COMPONENT :: END  -->

		<!-- Show Promot Sele/Discount Model :: START  -->
		<div v-if="selectGoalType == 1 && selectStyleType != ''">
			<promote-sale @close-capture-details="closeCaptureDetails" @formDataForAddNewCapture="addNewCaptureAndPublish" :prop_selectedGoalType="{selectGoalText, selectStyleText}"></promote-sale>
		</div>
		<!-- Show Promot Sele/Discount Model :: END -->

		<!-- Show Talk To Visitor Model :: START  -->
		<div v-if="selectGoalType == 2 && selectStyleType != ''">
			<talk-to-your-visitors @close-capture-details="closeCaptureDetails" @formDataForAddNewCapture="addNewCaptureAndPublish" :prop_selectedGoalType="{selectGoalText, selectStyleText}"></talk-to-your-visitors>
		</div>
		<!-- Show Talk To Visitor Model :: END  -->

		<!-- Show Grow Mailing List Model :: START  -->
		<div v-if="selectGoalType == 3 && selectStyleType != ''">
			<grow-mailing-list @close-capture-details="closeCaptureDetails" @formDataForAddNewCapture="addNewCaptureAndPublish" :prop_selectedGoalType="{selectGoalText, selectStyleText}"></grow-mailing-list>
		</div>
		<!-- Show Grow Mailing List Model :: END  -->

		<!-- Show Get Facebook Likes Model :: START  -->
		<div v-if="selectGoalType == 4 && selectStyleType != ''">
			<get-facebook-likes @close-capture-details="closeCaptureDetails" @formDataForAddNewCapture="addNewCaptureAndPublish" :prop_selectedGoalType="{selectGoalText, selectStyleText}"></get-facebook-likes>
		</div>
		<!-- Show Get Facebook Likes Model :: END  -->

		<!-- Show Announcement  Model :: START  -->
		<div v-if="selectGoalType == 5 && selectStyleType != ''">
			<announcement @close-capture-details="closeCaptureDetails" @formDataForAddNewCapture="addNewCaptureAndPublish" :prop_selectedGoalType="{selectGoalText, selectStyleText}"></announcement>
		</div>
		<!-- Show Announcement Model :: END  -->

		<!-- Show Text App Subscribe Model :: START  -->
		<div v-if="selectGoalType == 6 && selectStyleType != ''">
			<subscribe-text-app @close-capture-details="closeCaptureDetails" @formDataForAddNewCapture="addNewCaptureAndPublish" :prop_selectedGoalType="{selectGoalText, selectStyleText}"></subscribe-text-app>
		</div>
		<!-- Show Text App Subscribe Model :: END  -->

		<!-- Show Fortune Wheel Model :: START  -->
		<div v-if="selectGoalType == 7 && selectStyleType != ''">
			<fortune-wheel @close-capture-details="closeCaptureDetails" @formDataForAddNewCapture="addNewCaptureAndPublish" :prop_selectedGoalType="{selectGoalText, selectStyleText}"></fortune-wheel>
		</div>
		<!-- Show Fortune Wheel Model :: END  -->
	</div>
</template>
<script>
	import { BRow, BCol, BCard, BCardBody, BAvatar, BButton } from 'bootstrap-vue'
	import SelectGoal from "./SelectGoal"
	import SelectStyle from "./SelectStyle";
	import PromoteSale from "./new-capture-goal-details/PromoteSale";
	import TalkToYourVisitors from "./new-capture-goal-details/TalkToYourVisitors";
	import GrowMailingList from "./new-capture-goal-details/GrowYourMailingList";
	import FortuneWheel from "./new-capture-goal-details/FortuneWheel";
	import GetFacebookLikes from "./new-capture-goal-details/GetFacebookLikes";
	import SubscribeTextApp from "./new-capture-goal-details/SubscribeToTextApp";
	import Announcement from "./new-capture-goal-details/Announcement";
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

	export default {
		components: {
			BRow, BCol, BCard, BCardBody, BAvatar, BButton, TalkToYourVisitors, GrowMailingList, FortuneWheel, GetFacebookLikes, SubscribeTextApp,
			SelectGoal,
			SelectStyle,
			PromoteSale,
			Announcement,
			ToastificationContent
		},
		data() {
			return {
				selectGoalType: '',
				selectGoalText: '',
				selectSubGoalType: '',
				selectStyleType: '',
				selectStyleText: '',
				ShowPromotSeleDiscountModel: 0,
				ShowTalkToVisitorModel: 0,
				ShowGrowMailingListModel: 0,
				ShowFortuneWheelModel: 0,
				ShowGetFacebookLikesModel: 0,
				ShowSubscribeTextAppModel: 0,
				ShowAnnouncementModel: 0,
			}
		},
		methods: {
			selectedGoalType(goal) {
				this.selectGoalText = goal.goalDetail.title
				this.selectGoalType = goal.goal
				this.selectSubGoalType = goal.subgoal
			},
			selectedStyleType(style) {
				this.selectStyleType = style.style;
				this.selectStyleText = style.styleDetail.title;
			},
			/** CLOSE STYLE SECTION */
			closeStyleSection() {
				this.selectGoalType = ''
				this.selectGoalText = ''
				this.selectSubGoalType = ''
				this.selectStyleType = ''
				this.selectStyleText = ''
			},
			/** CLOSE CAPTURE DETAILS SECTION */
			closeCaptureDetails() {
				this.selectStyleType = ''
				this.selectStyleText = ''
			},

			addNewCaptureAndPublish(formData) {
				formData.siteId = this.$root.appSiteObject.siteId ?? '';
				formData.goal = this.selectGoalType ?? '';
				formData.subgoal = this.selectSubGoalType ?? '';
				formData.styleType = this.selectStyleType ?? '';
				formData.status = 0; // 0 = Paused
				this.$captureSettingAPI.post('add-new-capture-and-publish-settings', formData).then((response) => {
					if (response) {
						response = response.data;
						if (response.status) {
							this.$router.push({ name: 'capture-setting-new', params: { setting_id: response.setting_id } })
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Notification',
									icon: 'BellIcon',
									variant: 'success',
									text: response.message,
								},
							});
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: 'Notification',
									icon: 'BellIcon',
									variant: 'danger',
									text: response.message,
								},
							});
						}
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: response.message,
							},
						});
					}
				});
			}
		},
	}
</script>