<template>
	<div>
		<div class="card">
			<div class="card-header">
				<h4 class=" font-weight-bolder">{{ prop_selectedGoalType.selectGoalText }} > {{
					prop_selectedGoalType.selectStyleText
				}}</h4>
				<b-button variant="danger" class="" @click="$emit('close-capture-details')">Go Back</b-button>
			</div>
		</div>
		<div class="row justify-content-center">
			<b-card class="card col-md-7">
				<validation-observer ref="addNewCaptureForm">
					<b-form id="add-new-capture-form" ref="add-new-capture-form">
						<b-card-title>Promote a Sale</b-card-title>
						<b-row>
							<b-col md="12">
								<b-form-group>
									<label for="captureBarSay">What should your Capture say?</label>
									<validation-provider #default="{ errors }" name="captureBarSay" rules="required">
										<b-form-input name="captureBarSay" id="captureBarSay"
											placeholder="Checkout our latest sale" v-model="input"
											:state="errors.length > 0 ? false : null" />
									</validation-provider>
								</b-form-group>
							</b-col>

							<b-col md="12">
								<b-form-group>
									<label for="buttonSay">What should the button say?</label>
									<validation-provider #default="{ errors }" name="buttonSay" rules="required">
										<b-form-input name="buttonSay" id="buttonSay" placeholder="Shop Now"
											v-model="button" :state="errors.length > 0 ? false : null" />
									</validation-provider>
								</b-form-group>
							</b-col>

							<b-col md="12">
								<b-form-group>
									<label for="buttonURL">What URL should users go to when they click your
										button?</label>
									<validation-provider #default="{ errors }" name="buttonURL" rules="required|url"
										:custom-messages="{ required: 'Please enter URL', url: 'Please enter valid URL' }">
										<b-form-input name="buttonURL" id="buttonURL" placeholder="" v-model="url"
											:state="errors.length > 0 ? false : null"
											placeholder="https://example.com" />
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
						</b-row>
						<b-card-footer align="right">
							<b-button variant="primary" class="ml-2 float-right" @click.prevent="validationForm">
								Continue
							</b-button>
							<b-button variant="outline-danger" class="float-right"
								@click="$emit('close-capture-details')">
								Cancel
							</b-button>
						</b-card-footer>
					</b-form>
				</validation-observer>
			</b-card>
		</div>
	</div>
</template>

<script>
import { BRow, BCol, BButton, BCard, BInputGroup, BFormInput, BFormGroup, BCardTitle, BCardFooter, BForm } from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
	components: {
		BRow, BCol, BInputGroup, BFormGroup, BCard, BFormInput, BButton, BCardTitle, BCardFooter, BForm,
		ValidationProvider, ValidationObserver
	},
	data() {
		return {
			input: 'Checkout our latest sale',
			button: 'Shop now',
			url: '',
			required
		}
	},
	props: {
		prop_selectedGoalType: {
			type: Object,
		}
	},
	methods: {
		goToMangeSiteCapture() {
			this.$router.push('/manage-site');
		},
		validationForm() {
			this.$refs.addNewCaptureForm.validate().then(success => {
				if (success) {
					let formData = new FormData(document.getElementById('add-new-capture-form'));
					const data = {};
					for (let [key, val] of formData.entries()) { /* NEED TO CONVERT IT BEFORE USING NOT WITH XMLHTTPREQUEST */
						Object.assign(data, { [key]: val })
					}
					this.$emit("formDataForAddNewCapture", data);
				}
			})
		},
	},
}
</script>